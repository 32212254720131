import React from 'react'
const GoogleMap = () => {
  return (
    <div className='map-area'>
      <iframe
        title='google-maps-wrapper'
        src='https://www.google.com/maps/embed/v1/place?key=AIzaSyDz7pQEW0ipmLI5wuak10U2lYzCEXK2o_0&q=InnoAge-Technologies+PVT.+LTD.'
      />
    </div>
  )
}

export default GoogleMap
