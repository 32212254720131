import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import ContactForm from '../components/Contact/ContactForm'
import GoogleMap from '../components/Contact/GoogleMap'
import Footer from '../components/_App/Footer'

const ContactPage = () => {
  return (
    <Layout>
      <Seo title='Contact' description='React Out to Us on Phone, E-Mail or Visit Us' />

      <TopHeader />

      <Navbar />

      <ContactForm />

      <GoogleMap />

      <Footer />
    </Layout>
  )
}

export default ContactPage
