import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import ContactInfo from './ContactInfo'
import teamsHooks from '../../services/teams.service'
import { isNotNullUndefinedOrEmpty } from '../../helper/utilities'

const ContactForm = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phoneNo, setphoneNo] = useState('')
  const [sub, setsub] = useState('select')
  const [message, setmessage] = useState('')
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [ticket, setTicket] = useState(0)
  const [valid, setValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const sendMessage = async () => {
    setIsLoading(true)
    const tempID = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
    setTicket(tempID)
    const input = {
      name: name,
      email: email,
      phoneNo: phoneNo,
      sub: sub,
      message: message,
      ticket: tempID
    }
    console.log('input:' + JSON.stringify(input))
    await teamsHooks.supportQuery(input).then(
      () => {
        setIsEmailSent(true)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
      }
    )
  }

  const verifyToken = async response => {
    if (isNotNullUndefinedOrEmpty(response)) {
      setValid(true)
    }
  }

  const clearForm = e => {
    e.preventDefault()
    setname('')
    setemail('')
    setphoneNo('')
    setsub('select')
    setmessage('')
    setAgreeTerms(false)
    setIsEmailSent(false)
    setTicket(0)
    setValid(false)
  }

  return (
    <div className='contact-form-area pt-100 pb-70'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Contact Us</h2>
        </div>

        <div className='row pt-45'>
          <div className='col-lg-4'>
            <ContactInfo />
          </div>

          <div className='col-lg-8'>
            <div className='contact-form'>
              <form id='contactForm'>
                <div className='row'>
                  {isEmailSent && (
                    <div className='col-lg-12 pb-20'>
                      <div class='alert alert-success' role='alert'>
                        We have Received your Query & will respond in next 24-48 hours. Please keep
                        ticket Id:{ticket} for Reference.
                      </div>
                    </div>
                  )}
                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='your_name'>
                        Name <span>*</span>
                      </label>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        className='form-control'
                        required
                        value={name}
                        onChange={e => setname(e.target.value)}
                        placeholder='Name'
                      />
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='your_email'>
                        Email <span>*</span>
                      </label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        value={email}
                        onChange={e => setemail(e.target.value)}
                        className='form-control'
                        required
                        placeholder='Email'
                      />
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='your_phone'>Phone</label>
                      <input
                        type='text'
                        name='phone_number'
                        id='phone_number'
                        value={phoneNo}
                        onChange={e => setphoneNo(e.target.value)}
                        className='form-control'
                        placeholder='Phone Number'
                      />
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='form-group'>
                      <label htmlFor='your_subject'>
                        Inquiry Category<span>*</span>
                      </label>
                      <select
                        name='msg_subject'
                        id='msg_subject'
                        className='form-select-control'
                        value={sub}
                        onChange={e => setsub(e.target.value)}
                      >
                        <option value='select'>Select Reason for Contacting Us</option>
                        <option value='Product-Demo'>Product Demo</option>
                        <option value='Services'>Services Query</option>
                        <option value='Career'>Career/Jobs</option>
                        <option value='Other'>Other Query (Please Provide Description)</option>
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-12 col-md-12'>
                    <div className='form-group'>
                      <label htmlFor='your_message'>
                        How can we help you? <span>*</span>
                      </label>
                      <textarea
                        name='message'
                        className='form-control'
                        id='message'
                        cols='30'
                        rows='4'
                        value={message}
                        onChange={e => setmessage(e.target.value)}
                        required
                        placeholder='Your Inquiry Details'
                      />
                    </div>
                  </div>

                  <div className='col-lg-12 col-md-12'>
                    <div className='agree-label'>
                      <input
                        type='checkbox'
                        id='chb1'
                        checked={agreeTerms}
                        onChange={() => setAgreeTerms(!agreeTerms)}
                      />
                      <label htmlFor='chb1'>
                        Yes, I would like InnoAge to contact me based on the information provided
                        above.{' '}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-12 col-md-12'>
                    <div className='agree-label'>
                      <label htmlFor='chb1'>
                        Read our{' '}
                        <a href='/privacy-policy' target='_blank' rel='noreferrer'>
                          Privacy Policy
                        </a>{' '}
                        for details on how your information may be used. I understand that using
                        below captcha would place cookies. I am aware that I can raise the same
                        query to{' '}
                        <a href='mailto:ask@innoage.in' target='_blank' rel='noreferrer'>
                          ask@innoage.in
                        </a>{' '}
                        without using below captcha. Read more in the{' '}
                        <a href='/terms-condition' target='_blank' rel='noreferrer'>
                          Terms & Conditions
                        </a>
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-12 col-md-12 text-center'>
                    <div className='row justify-content-center mb-3'>
                      <div className='col-auto'>
                        <ReCAPTCHA
                          sitekey='6LcKPB8hAAAAAOqAoOgFtAgGSJn_KsjmQ-O6nEje'
                          onChange={response => verifyToken(response)}
                        />
                      </div>
                    </div>
                    <div className='row justify-content-center'>
                      <div className='col-auto'>
                        <button
                          type='button'
                          className='default-btn btn-bg-one border-radius-50'
                          // disabled={isLoading || isEmailSent || !valid}
                          onClick={e => {
                            clearForm(e)
                          }}
                        >
                          Clear
                        </button>
                      </div>
                      <div className='col-auto'>
                        <button
                          type='button'
                          className='default-btn btn-bg-two border-radius-50'
                          disabled={
                            !agreeTerms ||
                            !message ||
                            !name ||
                            !email ||
                            isLoading ||
                            isEmailSent ||
                            !valid
                          }
                          onClick={() => {
                            sendMessage()
                          }}
                        >
                          {isLoading
                            ? (
                                'Sending ...'
                              )
                            : (
                              <>
                                Send <i className='bx bx-check-circle' />
                              </>
                              )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
