import React from 'react'

const ContactInfo = () => {
  return (
    <div className="contact-info mr-20">
      <span>Contact Info</span>
      <h2>Reach Out to Us</h2>
      <p>You may choose to reach out to us directly on any of the below</p>
      <ul>
        <li>
          <div className="content">
            <i className="bx bx-phone-call" />
            <h3>Phone Number</h3>
            <a href="tel:+91-8595824647">+91-8595824647</a>
          </div>
        </li>
        <li>
          <div className="content">
            <i className="bx bxs-map" />
            <h3>Greater Noida Office</h3>
            <span>
              InnoAge-Technologies PVT. LTD., D-81 Site 5 EPIP, Kasna, Greater Noida, Uttar Pradesh
              201306
            </span>
          </div>
          <hr />
          <div className="content">
            <i className="bx bxs-map" />
            <h3>Noida Office</h3>
            <span>
              Nukleus Coworking & Managed Offices, InnoAge-Technologies PVT. LTD. Floor - 5, Plot
              Number - 29, Sector 142, Noida, Uttar Pradesh 201305
            </span>
          </div>
        </li>
        <li>
          <div className="content">
            <i className="bx bx-message" />
            <h3>Contact Info</h3>
            <a href="mailto:ask@innoage.in">ask@innoage.in</a>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default ContactInfo
